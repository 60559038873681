<template>
  <div>
    <banner
      title="Daftar Pencetak Buku Kurikulum K-13"
      description="Urutan penerbit dibawah ini berdasarkan abjad"
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/pencetak-buku.svg')"
    />
    <section v-if="$store.state.loadProvider" id="content" class="mt-4">
      <div class="container">
        <div class="loading text-center">
          <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
        </div>
      </div>
    </section>
    <section v-else class="my-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="input-group mb-3" style="width: 300px;">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <i class="fa-solid fa-magnifying-glass"></i>
              </span>
            </div>
            <input
              v-model="search"
              class="form-control pl-0 border-left-0"
              placeholder="Cari nama"
            />
          </div>
          <table class="table border">
            <thead class="thead-light">
              <tr>
                <th>No</th>
                <th>Nama</th>
                <th>Alamat</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(provider, index) in filteredData" :key="index">
                <td>{{ index + 1 + (pageNumber - 1) * perPage }}</td>
                <td>{{ provider.name }}</td>
                <td>{{ provider.address }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="99">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="filteredData.length"
                  >
                    <span>
                      Menampilkan {{ perPage }} dari
                      {{ providers.length }} hasil
                    </span>

                    <pagination
                      class="m-0"
                      :current-page="pageNumber"
                      :total-data="providers.length"
                      :total-page="totalPage"
                      :key="totalPage"
                      :max-page="3"
                      @change="onChangePage"
                    />
                  </div>
                  <div v-else class="text-center">
                    Data tidak ditemukan
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
td {
  padding: 1rem;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import Banner from "../../components/homepage/Banner.vue";
import Pagination from "../../components/common/Pagination.vue";

export default {
  components: { Banner, Pagination },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Pencetak Buku Kurikulum K-13",
          url: "",
        },
      ],
      perPage: 10,
      pageNumber: 1,
      search: "",
    };
  },
  computed: {
    ...mapState(["providers"]),
    filteredData() {
      return this.sortArray(this.providers)
        .slice(
          (this.pageNumber - 1) * this.perPage,
          this.pageNumber * this.perPage
        )
        .filter(({ name }) =>
          name.toLowerCase().includes(this.search.toLowerCase())
        );
    },
    totalPage() {
      const providersLength = this.search
        ? this.filteredData.length
        : this.providers.length;
      return Math.ceil(providersLength / this.perPage);
    },
  },
  methods: {
    ...mapActions(["fetchAllProvider"]),
    sortArray(array) {
      return _.orderBy(array, ["name"], ["asc"]);
    },
    onChangePage(page) {
      this.pageNumber = page;
    },
  },
  created() {
    this.fetchAllProvider("k13");
  },
};
</script>

<style></style>
